.toolbarClassName {}

.wrapperClassName {
    flex-shrink: 1;
}

.editorClassName {
    padding: 0 15px;
    height: auto !important;
    min-height: 600px;
    border: 1px solid black;
    cursor: text;
}