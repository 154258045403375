.react-paginate {
    display: flex;
    list-style: none;
    align-items: center;
    margin-left: auto;
    .page {
        border-radius: 15px;
        padding: 0.5rem;
        background: white;
    }
    .page-active {
        color: #fff;
        background: #5369f8;
    }
    .next-page {}
    .prev-page {}
}